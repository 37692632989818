// routes
import {paths} from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';
import {usePermission} from 'src/hooks/use-permission';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{width: 1, height: 1}} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

// ----------------------------------------------------------------------

export function useNavData() {
  const {isCreator, isApprover} = usePermission()
  const operations = {title: 'Операции', path: paths.dashboard.root, icon: icon('operations')}
  const registers = {title: 'Реестры', path: paths.registers.root, icon: icon('registers')}
  const transports = {title: 'Транспорт', path: paths.transports.root, icon: icon('transports')}

  const items = []

  items.push(operations)
  // items.push(transports)
  // items.push(userManual)
  if (isApprover || isCreator) {
    items.push(registers)
  }

  const data = [
    {
      subheader: 'ОСНОВНЫЕ',
      items,
    },
  ]

  return data;
}

export function useSubNavData() {
  const {isCreator, isApprover} = usePermission()
  const userManual = {
    title: 'Руководство пользователя', path: "https://support.mozen.io/knowledge_base/",
    icon: icon('usermanual')
  }

  const items = []

  items.push(userManual)

  const data = [
    {
      items,
    },
  ]

  return data;
}
