import {useSelector} from 'react-redux';

export function usePermission() {
  const selectedEndpointId = useSelector(store => store?.endpoint.selectedEndpoint?.id)
  const userPermission = useSelector(store => store?.user?.permissions)
  const permissions = userPermission?.endpoints ? userPermission?.endpoints[selectedEndpointId] : {}
  // const comissions = useSelector(store => store?.endpoint?.endpoints?.data.filter(i => i.id === selectedEndpointId)[0]?.data?.comissions)

  const isCreator = permissions?.is_bid_registry_creator
  const isApprover = permissions?.is_bid_registry_approver

  return {
    isCreator,
    isApprover,
  };
}
