import { Stack } from '@mui/material'

export default function Version(props) {

  const version = "Версия " + process.env.REACT_APP_VERSION + (process.env.REACT_APP_VERSION_DESCRIPTION ? " от " + process.env.REACT_APP_VERSION_DESCRIPTION : "")

  return (
    <Stack mt={1} px={4} mb={0} alignItems="center" sx={{
      fontSize: "12px",
      color: "#aeb7c2",
    }} {...props}>
      {version}
    </Stack>
  )
}