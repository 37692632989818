import PropTypes from 'prop-types';
import { useEffect } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import Scrollbar from 'src/components/scrollbar';
import { usePathname } from 'src/routes/hooks';
import { NavSectionVertical } from 'src/components/nav-section';
//
import { NAV } from '../config-layout';
import { useNavData, useSubNavData } from './config-navigation';
import { NavToggleButton, NavUpgrade } from '../_common';
import Version from 'src/components/version';

// ----------------------------------------------------------------------

export default function NavVertical({ openNav, onCloseNav }) {

  const pathname = usePathname();

  const lgUp = useResponsive('up', 'lg');

  const navData = useNavData();
  const subNavData = useSubNavData()

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        direction='row'
        alignItems='center'
        justifyContent="space-between"
        sx={{
          p: 2,
          mb: 2.5,
          background: '#F9FAFB',
          borderBottom: '1px solid #CDD5DC',
        }}
      >
        <Logo type='fullLogo' />
        <NavToggleButton />
      </Stack>

      <NavSectionVertical
        data={navData}
      />

      <Box sx={{ flexGrow: 1 }} />

      <NavSectionVertical
        data={subNavData}
      />

      <Version alignItems="left"></Version>

      <NavUpgrade />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            background: '#fff'
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
              '& .MuiBox-root': {
                display: "flex",
                flexDirection: "column"
              }
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

NavVertical.propTypes = {
  onCloseNav: PropTypes.func,
  openNav: PropTypes.bool,
};
